import React from 'react';

const IndexPage = () => (
  <div className="hero is-light is-large">
    <div className="hero-body">
      <section className="section">
        <div className="container has-text-centered">
          <h1 className="title">
            — Laura Calabro is a full stack software engineer, passionate about
            learning new technologies, living in the New York metro area.
          </h1>
        </div>
      </section>
    </div>
  </div>
);

export default IndexPage;
